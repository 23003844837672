import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import useSolutions from "../../hooks/solutionListHook"
import Fade from "react-reveal/Fade"

const Component = styled.section`
  display: grid;
  //grid-auto-flow: row;
  grid-template-columns: repeat(6, 2fr);
  grid-template-rows: auto auto;
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  align-items: flex-start;
  justify-content: center;

  & > div:nth-child(n) {
    grid-column: span 3;
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`

const Block = styled.div`
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
  grid-column: span 4;
  display: flex;
  flex-flow: column;

  text-align: left;
  align-content: flex-start;
  color: var(--color-black-500);
  justify-content: space-between;
  transition: var(--transition);

  p {
    align-self: flex-start;
  }

  h6 {
    margin: 0;
    font-weight: bold;
  }

  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 2rem;
  }
`

const Content = styled.div`
  border-top: 0.2px var(--color-background-greyer) solid;
  padding: var(--var-padding-s);
  padding-top: 20px;

  &:hover {
    border-top: 0.2px var(--color-brand-strong) solid;
  }
`

const PostListImage = styled(Img)`
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 40px;
  height: 80px;
  width: 80px;
  transition: transform 0.2s ease-in, box-shadow 0.3s ease-in-out;
`

const Paragraph = styled.p`
  font-size: 0.95rem;
  line-height: 200%;
  color: var(--color-secondary-500);
`

const Solution = () => {
  const solutionList = useSolutions()

  return solutionList.map(solution => (
    <Fade bottom>
      <Block className="noeffect" key={solution.title}>
        {" "}
        <PostListImage
          fluid={solution.cover.childImageSharp.fluid}
          alt={solution.title}
        />
        <Content>
          <h6>{solution.title}</h6>
          <Paragraph className="noeffect"> {solution.excerpt}</Paragraph>
        </Content>
      </Block>
    </Fade>
  ))
}

const SolutionList = () => {
  return (
    <Component>
      <Solution />
    </Component>
  )
}

export default SolutionList
