import { graphql, useStaticQuery } from "gatsby"

const useSolutions = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { fileAbsolutePath: { regex: "/offering/" } }) {
        nodes {
          frontmatter {
            title
            tldr
            cover {
              publicURL
              size
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map(solutionEdge => ({
    cover: solutionEdge.frontmatter.cover,
    title: solutionEdge.frontmatter.title,
    excerpt: solutionEdge.frontmatter.tldr,
  }))
}

export default useSolutions
