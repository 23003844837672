import React from "react"
import Faq from "./faq"
import styled from "styled-components"
import Fade from "react-reveal/Fade"

const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
`

const Title = styled.h2`
  font-family: var(--font-primary);
  color: var(--color-accent-medium);
  padding-bottom: 16px;
  margin-top: none;
`

const FaqData = [
  {
    question: "What is GECKO?",
    answer: `GECKO stands for Governance Engine for Compliance Knowledge and Operations. It is a web based solution that allows financial 
    services firms to prove out every step of compliance to ensure they adhere to applicable legislation and avoid landing themselves into
    a ‘fine mess’ with regulators.`,
  },
  {
    question: "Why use GECKO?",
    answer: `GECKO is a solution woven together by funds leader in the industry and designed by tech experts to create an ideal solution.`,
  },
  {
    question: "What is your business model?",
    answer: `Our flexible, value-driven services and client-focused approach are what makes our partnership model. We mainly operate as 
    SaaS however, our clients decide how they manage and host their data while we add value, time and unlock resources for their business.`,
  },
  {
    question: "Who is your cloud provider?",
    answer: `AWS or Digital Ocean, depending on our clients preference.`,
  },
  {
    question: "Can you integrate with existing tech structure?",
    answer: `Yes, GECKO is an out of the box solution that can be structured around a firms daily processes and procedures, aligning governance structures with their organisational strategy.`,
  },

  {
    question: "What do your clients use your solution for currently?",
    answer: `Our clients use our solutions for various activities including, Fund Launches & On-boarding, 
    Operation Due Diligence, Control Procedure Monitoring, Outsourcing & Third Party Management, and much more.`,
  },
]

const FaqsList = () => {
  return (
    <FAQWrapper>
      <Title>Frequently asked questions</Title>
      {FaqData.map((faq, i) => (
        <Fade left>
          <Faq key={"faq_" + i} question={faq.question} answer={faq.answer} />
        </Fade>
      ))}
      <br />
      <br />
    </FAQWrapper>
  )
}

export default FaqsList
