import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Boxed from "../bits/box"
import Column from "../bits/column"
import BigButton from "../bits/bigButton"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Hero from "../components/Hero/hero"
import WorkIndex from "../components/WorkList/workList"
import SolutionList from "../components/SolutionList/solutionlist"
import FaqsList from "../components/FAQ/faqList"
import ContactForm from "../components/ContactForm/form"
import Testimonial from "../components/Testimonial/index"
import sphell from "../static/images/sp_hell.png"
import Modal from "../components/Modal/index"
import DemoButton from "../bits/demoButton"

// const WorkIndex = lazy(() => import('../components/WorkList/workList'));
// const BlogListing = lazy(() => import('../components/BlogList/blogList'));
// const SolutionList = lazy(() => import('../components/SolutionList/solutionlist'));
// const FaqsList = lazy(() => import('../components/FAQ/faqList'));
// const ContactForm = lazy(() => import('../components/ContactForm/form'));
// const Hero = lazy(() => import('../components/Hero/hero'));

const Box = styled(Boxed)`
  max-width: var(--page-container-l);
`

const Row = styled.section`
  padding: var(--var-padding-m) 16px;
  background: white;
`

const ColumnSpaced = styled(Column)`
  grid-gap: 3rem;
`

const UnevenColumn = styled(ColumnSpaced)`
  grid-template-columns: 5fr 3fr;
`

const GreyRow = styled(Row)`
  background: var(--color-white-300);
`

const StickyBox = styled.div`
  height: 100%;
`

const RightStickyBox = styled.div`
  @media only screen and (min-width: 600px) {
    order: 1;
  }
`

const StickyWrapper = styled.div`
  position: relative;
  padding: var(--var-padding-m) 0;

  @media only screen and (min-width: 600px) {
    position: sticky;
    top: 3rem;
  }
`

const Subtitle = styled.p`
  font-size: 1.025rem;
  font-family: var(--font-secondary);
  font-weight: 500;
`

const Blog = styled.section`
  //display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  align-items: flex-start;

  & > div:nth-child(2) {
    grid-column: span 4;
  }

  & > div:nth-child(3) {
    grid-column: span 4;
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`

const BlogIntro = styled.div`
  margin-bottom: var(--var-padding-m);
  p {
    color: var(--color-secondary-700);
  }
`

const ShortBoxed = styled(Boxed)`
  max-width: 50vw;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 1024px) {
    max-width: 50vw;
  }
  @media only screen and (max-width: 768px) {
    max-width: 80vw;
  }
`
const InvertRow = styled(Row)`
  background: var(--color-secondary-700);
`

const PhotoFrame = styled.figure`
  grid-area: Photo;
  width: 100%;
  margin: 0;
  padding: 2rem 0;
  padding-bottom: 0px;
  border: 2px #060b1f solid;
`

const P1 = styled.div`
  grid-area: P1;
  max-width: 60ch;
`
const SpHell = styled(Row)`
  background: var(--color-background-500);
  padding-bottom: 0px;
  padding-top: 0px;
`

const IndexPage = ({ data }) => {

  const createRef = useRef();

  useEffect(() => 
  {
    // if(localStorage.getItem('demoModal') === null) 
    // {
    //   localStorage.setItem('demoModal', true);
    //   console.log('demo modal set now!');


      // const timer = setTimeout(() => {
      //   createRef.current.open();
      // }, 2000);
      // return () => clearTimeout(timer);



    // }
    // else
    // {
    // console.log('already set');
    // }
                
  },[]);

 


  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <SpHell className="full-bleed">
        <Boxed>
          <P1>
            <small>GECKO helps you steer away from</small>
            <h2 className="no-margin">Spreadsheet Hell</h2>
            <p>
              We understand the headaches associated with organising data and
              managing compliance related projects on spreadsheets.
            </p>
          </P1>
          <PhotoFrame>
            {/* <Img
              fluid={data.cover.childImageSharp.fluid}
              alt="Spreadsheet Hell"
            /> */}
            <img style={{marginLeft:"10px"}} src={sphell} alt="Spreadsheet hell" />
          </PhotoFrame>
          <p>
            GECKO has the ability to replicate your manual spreadsheet data and
            give you a real-time Governance Dashboard with an end to end
            holistic view of current, progress and upcoming deadlines.
          </p>
          <p>
          On average every employee can save <b>6+ hours</b> in a week by automating the repetitive aspects of their work!
          </p>
        </Boxed>
      </SpHell>
      {/* <div id="main_modal">
      <Modal ref={createRef}>
        <h2 style={{color:"white"}}> See GECKO in Action </h2>
        <p style={{color:"white"}}>Fill out the form so we can respond better to your query and give you a hands-on 
          demonstration of our solution. Our team of experts look forward to discussing your 
          business needs. 
        </p>
        <br/>
        <DemoButton style={{color:"orange"}} href="/#contact" text="Request a Demo"/>
      </Modal>
      </div> */}
      <Testimonial client="Paul Nunan, Country Head, Jupiter Asset Management (Europe) Limited" 
      statement="Since working with GECKO, we have been continually impressed by their willingness to work 
      closely with us to ensure that our requirements are met and that we are using all the functionality 
      within the system appropriately. They understand our business and work in partnership with us and continue 
      to enhance the product based on their customer requirements."/>
      <GreyRow className="full-bleed">
        <Box>
          <ColumnSpaced>
            <StickyBox>
              <StickyWrapper>
                <br />
                <br />
                <h2 className="no-margin">GECKO Solutions</h2>
                <p>
                  Our suite of inbuilt workflows are designed by subject matter
                  experts who can address regulatory hotspots and carefully
                  adapt the right solution.
                </p>
                <BigButton href="/usecases" />
              </StickyWrapper>
            </StickyBox>
            <WorkIndex />
          </ColumnSpaced>
        </Box>
      </GreyRow>
      <Row className="full-bleed">
        <Box>
          <ColumnSpaced>
            <StickyBox>
              <StickyWrapper>
                <br />
                <br />
                <h2 className="no-margin">Why choose GECKO</h2>
                <p>
                Our team of professionals understand how busy you and your team 
                can be which is why the GECKO system is already operational and 
                deployments across multiple departments can be completed with 
                speed and simplicity.
                </p>
              </StickyWrapper>
            </StickyBox>
            <SolutionList />
          </ColumnSpaced>
        </Box>
      </Row>
      <InvertRow id="resume" className="full-bleed">
        <ShortBoxed>
          <FaqsList />
        </ShortBoxed>
      </InvertRow>
      <div id="contact">
        <ContactForm />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    cover: file(relativePath: { eq: "sp_hell.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
