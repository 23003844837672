import React from "react"
import styled from "styled-components"
import GECKO_GERRY from "../../static/images/gerry_gecko.png"
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = '6LcykUkfAAAAAAhWEgGB6ZODlqdvtqiht0xtw0fn'


const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white-300);
  padding: 50px 50px;
  padding-bottom: 0px;
  //border-top: 10px solid #006cbf;
  width: 100vw;
`

const FormButton = styled.button`
  display: block;
  line-height: 24pt;
  padding: 0 20px;
  border: none;
  background: #006cff;
  color: white;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  //border-bottom: 2px solid transparent;
  outline: none;
  }

  &:hover {
    background: #fcfcfc;
    color: #006cff;
  }
`

const Form = styled.form`
  padding: 25px 25px;
  //border-top: 1px solid #006cff;
  width: 80vw;
  @media only screen and (min-width: 768px) {
    max-width: 60vw;
  }
`

const Label = styled.label`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 24pt;
`

const Input = styled.input`
  display: block;
  width: 100%;
  line-height: 24pt;
  margin-bottom: 24pt;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #fff;
  padding-left: 5px;

  &:focus {
    border: 1px solid #ff9f00;
  }
`

const TextArea = styled.textarea`
  border: none;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #fff;
  padding-left: 5px;
  resize: none;
  &:focus {
    border: 1px solid #ff9f00;
  }
`

const EM = styled.em`
  color: #ff9f00;
  font-weight: bold;
`
const Text = styled.p`
  line-height: 24pt;
  font-size: 0.75rem;
  font-style: italic;
`

const Header = styled.h2`
  margin-top: none;
`

const GECKOWrapper = styled.div`
  display: flex;
  width: 115.5px;
  height: 180px;
  background-image: url(${GECKO_GERRY});
  background-size: cover;
  margin-bottom: 1rem;
`
const InvisibleField = styled.div`
  display:none;
`

const ContactForm = () => {

  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode ({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
    .then(() => navigate(form.getAttribute('action')))
    .catch(error => alert(error))
  }

  return (
    <FormWrapper>
      <Header>Contact Us</Header>
      <Form name="contact_us" method="POST" data-netlify-recaptcha="true" data-netlify="true" 
      action="/thank-you"
      onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact_us" />
        <Label for="name">
          Name <EM>&#x2a;</EM>
        </Label>
        <Input
          name="name"
          placeholder="What should we call you?"
          required
          type="text"
          onChange={handleChange}

        />

        <Label for="email">
          Email <EM>&#x2a;</EM>
        </Label>
        <Input
            name="email"
            placeholder="Where can we contact you?"
            required
            type="email"
            onChange={handleChange}

        />

        <Label for="subject">
          Subject <EM>&#x2a;</EM>
        </Label>
        <select name="subject" id="subject" required onChange={handleChange}>
          <option value="none">----Choose----</option>
          <option value="Demo Request">Request a Demo</option>
          <option value="Product Brochure">Request Product Brochure</option>
          <option value="Talk">Speak to Management</option>
          <option value="Other">Other</option>
        </select>
        <br />
        <br />
        <Label for="message">
          Message <EM>&#x2a;</EM>
        </Label>
        <TextArea
            name="message"
            placeholder="Please provide details on your pain points, so we can respond better to your query."
            rows="4"
            required
            onChange={handleChange}
        ></TextArea>
        <Text>
          Please note that we aim to get back to you within 2 business days.
        </Text>
        <Recaptcha
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        size="normal"
        id="recaptcha-google"
        onChange={() => setButtonDisabled(false)}
      />
        <FormButton style={ buttonDisabled ? { cursor: 'not-allowed' } : {}} type="submit" disabled={buttonDisabled}>Send</FormButton>
      </Form>
      

      <GECKOWrapper />
    </FormWrapper>
  )
}

export default ContactForm
