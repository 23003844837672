import React from 'react';
import styled from 'styled-components';
import './index.css';
const TestimonialWrapper = styled.div`
    width: 100vw;
    background-color: var(--color-black);
`

const Statement = styled.p`
    font-size: 22px;
    font-weight:bold;
    color: var(--color-accent-strong);
`

const Client = styled.p`
    font-size: 20px;
    font-style: italic;
    opacity: 0.9;
    color: var(--color-accent-strong);
`

const TwoColumn = styled.div`
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 20px;
  font-size:15px;
  margin-bottom: 15px;
  display:flex;
  justify-content:center;

  @media screen and (max-width: 800px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`

const Row = styled.section`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
`
const Logo = styled.img`
  height: 300px;
  width: 300px;
`

const LogoWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content: center;
  margin: 20px;
`

const StatementWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding-top: 20px;

`

const Testimonial = props => (
 <TestimonialWrapper>
     <Row>
         <TwoColumn>
             <LogoWrapper>
                <Logo src="https://gecko-public.s3-eu-west-1.amazonaws.com/website-public-asset/Jupiter.png" alt="Jupiter Logo"></Logo>
             </LogoWrapper>
         </TwoColumn>
        
         <TwoColumn>
             <StatementWrapper>
            <Statement>
                <q></q>{props.statement}<q></q>
            </Statement>
            <Client>
                - {props.client}
            </Client>
            </StatementWrapper>
         </TwoColumn>
        </Row>
 </TestimonialWrapper>

)

export default Testimonial