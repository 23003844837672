import React, { useState } from "react"
import styled from "styled-components"

import { useSpring, animated } from "react-spring"
import useResizeAware from "react-resize-aware"

const FaqWrapper = styled.div`
  margin-bottom: var(--var-padding-s);
`

const FaqQuestion = styled.div`
  cursor: pointer;
  background: var(--color-secondary-700);
  font-weight: 800;
  color: var(--color-accent-medium);
`

const FaqAnswer = styled.div`
  overflow: hidden;
  color: var(--color-accent-light);
  opacity: 0.7;
  //border-bottom: 1px var(--color-accent-medium) dashed;
`

const Faq = props => {
  const { question, answer } = props
  const [isOpen, toggleOpen] = useState(false)
  const [resizeListener, { height }] = useResizeAware()

  const animProps = useSpring({
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <FaqWrapper>
      <div onClick={() => toggleOpen(!isOpen)}>
        <FaqQuestion>{question}</FaqQuestion>
        <animated.div style={{ ...animProps }}>
          <FaqAnswer
            style={isOpen ? { display: "block" } : { display: "none" }}
          >
            <span style={{ position: "relative" }}>
              {resizeListener}
              {answer}
            </span>
          </FaqAnswer>
        </animated.div>
      </div>
    </FaqWrapper>
  )
}

export default Faq
